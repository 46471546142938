/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useApi } from '../../hooks/useApi';
import styles from './distribution.module.css';
import { useStores } from '../../hooks/useStore';
import { observer } from 'mobx-react';
import TypesDeal from '../../api/managers/dto/GetTypesResponse.dto';
import { useEffect, useState } from 'react';
import { Input } from '../../components/UI/input/input';
require('moment/locale/ru');
import tinycolor from 'tinycolor2';
import { ReactComponent as CrossIco } from './icon/cross.svg';
import Tags from '../../api/managers/dto/GetTagsResponse.dto';
import { Button } from '../../components/UI/Button/Button';
import cn from 'classnames';
import Leagues from '../../api/managers/dto/GetLeaguesResponse.dto';
import { Managers as ManagersModel } from '../../models/managers';
import ManagersList from '../../api/managers/dto/GetManagersListResponse.dto';
import Select from '../../components/UI/Select/Select';


const Distribution: React.FC = observer(() => {
	const api = useApi();
	const store = useStores();

	const [typesDeals, setTypesDeals] = useState<TypesDeal[]>([]);
	const [searchedTags, setSearchedTags] = useState<Tags[]>([]);

	const [leagues, setLeagues] = useState<Leagues[]>([]);
	const [searchedTypes, setSearchedTypes] = useState<string[]>([]);

	const [activeCategory, setActiveCategory] = useState<'typeDeals' | 'leagues' | 'managers'>('typeDeals');

	const [managersAxl, setManagersAxl] = useState<ManagersModel[]>([]);
	const [managersList, setManagersList] = useState<ManagersList[]>([]);

	const [leaguesList, setLeaguesList] = useState<{id: string, name: string}[]>([]);

	useEffect(() => {
		const leaguesListNew = leagues.map((league) => league.league_name);
		const uniqueLeagues = leaguesListNew.filter((item, index) => leaguesListNew.indexOf(item) === index);
		setLeaguesList(uniqueLeagues.map((league) => ({id: league, name: league})));
	}, [leagues]);

	useEffect(() => {
		(async () => {
			try {
				store.notificationsStore.showPreloader();
				const {body: types} = await api.managers.getTypesDelas();

				if (types) {
					setTypesDeals(types);
				}

				const {body: getLeagues} = await api.managers.getLeagues();

				if (getLeagues) {
					setLeagues(getLeagues);
				}

				const {body: managers} = await api.managers.getManangers(['Консультант', 'Консультант 2', 'Консультант 3', 'Консультант 0', 'Консультант 4']);

				if (managers) {
					const managersNew = managers.filter((manager) => manager.name !== '' && manager.name !== ' ' && manager.id !== '' && manager.id !== ' ');
					setManagersAxl(managersNew);
				}

				const {body: managersL} = await api.managers.getListManagers();

				if (managersL) {
					setManagersList(managersL.sort((a, b) => (a.league_name > b.league_name) ? 1 : -1));
				}

			} catch (error) {
				store.notificationsStore.setError('Ошибка загрузки типов сделок');
			} finally {
				store.notificationsStore.hidePreloader();
			}
		}
		)();
	}, []);

	const searchTags = async (search: string) => {
		try {
			const {body: tags} = await api.managers.getTags(search);

			if (tags) {
				setSearchedTags(tags);
			}

		} catch (error) {
			store.notificationsStore.setError('Ошибка загрузки тегов');
		}
	}

	const [activeSearchName, setActiveSearchName] = useState<string>('');

	const updateTypes = async () => {
		try {
			store.notificationsStore.showPreloader();
			await api.managers.addUpdateDealTypes(typesDeals);
			store.notificationsStore.setNotifications('Типы сделок обновлены');
		} catch (error) {
			store.notificationsStore.setError('Ошибка обновления типов сделок');
		} finally {
			store.notificationsStore.hidePreloader();
		}
	}

	const addType = () => {
		const newTypes = [...typesDeals];
		newTypes.push({type_name: '', tags: [], to_count_conversions: false, priority: 0});
		setTypesDeals(newTypes);
	}

	const [activeSearchLeague, setActiveSearchLeague] = useState<string>('');

	const addLeague = () => {
		const newLeagues = [...leagues];
		newLeagues.push({league_name: '', norms: []});
		setLeagues(newLeagues);
	}

	const updateLeagues = async () => {
		try {
			store.notificationsStore.showPreloader();
			await api.managers.addUpdateLeague(leagues);
			store.notificationsStore.setNotifications('Лиги обновлены');
		} catch (error) {
			store.notificationsStore.setError('Ошибка обновления лиг');
		} finally {
			store.notificationsStore.hidePreloader();
		}
	}

	const searchTypes = async (name: string | null) => {
		try {
			const {body: types} = await api.managers.getDealsTypesNames(name);

			if (types) {
				setSearchedTypes(types);
			}

		} catch (error) {
			store.notificationsStore.setError('Ошибка загрузки типов сделок');
		}
	}

	const addManager = () => {
		const newManagers = [...managersList];
		newManagers.push({manager_id: managersAxl[0].id, manager_name: managersAxl[0].name, league_name: leagues[0].league_name, work_days: {monday: true, tuesday: true, wednesday: true, thursday: true, friday: true, saturday: true, sunday: true}});
		setManagersList(newManagers);
	}

	const updateManagers = async () => {
		try {
			store.notificationsStore.showPreloader();
			await api.managers.addUpdateManager(managersList);
			store.notificationsStore.setNotifications('Менеджеры обновлены');
		} catch (error) {
			store.notificationsStore.setError('Ошибка обновления менеджеров');
		} finally {
			store.notificationsStore.hidePreloader();
		}
	}

	return (
		<>
			<div className={styles.container}>
				<div className={styles.pageName}>
					<div className={styles.top}>
						<div className={styles.title}>
							<h2>Распределение лидов</h2>
							<div style={{width: '100%', height: 1, background: 'grey', marginTop: 20, opacity: 0.2}}/>
						</div>
					</div>

					<div className={styles.category}>
						<div className={cn(styles.categoryName, {[styles.categoryActive]: activeCategory === 'typeDeals'})} onClick={() => {setActiveCategory('typeDeals');}}>Типы сделок</div>
						<div className={styles.separator}/>
						<div className={cn(styles.categoryName, {[styles.categoryActive]: activeCategory === 'leagues'})} onClick={() => {setActiveCategory('leagues');}}>Лиги</div>
						<div className={styles.separator}/>
						<div className={cn(styles.categoryName, {[styles.categoryActive]: activeCategory === 'managers'})} onClick={() => {setActiveCategory('managers');}}>Менеджеры</div>
					</div>

				</div>

				{ activeCategory === 'typeDeals' &&
				<div>
					<div>
						{typesDeals.map((type, i) => (
							<>
								<div style={{display: 'flex', marginTop: 10, marginBottom: 10, flexWrap: 'wrap'}}>

									<div style={{margin: 5, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center'}}>
										<Input 
											textLabel='Название типа' type='text' id='text' placeholder='Введите название типа'
											value={type.type_name}
											onChange={(e) => {
												const newTypes = [...typesDeals];
												newTypes[i].type_name = e.target.value;
												setTypesDeals(newTypes);
											}}
											style={{maxWidth: 300, margin: 0}}
										/>

										<div style={{display: 'flex', alignItems: 'center', margin: 5}}>
											<p style={{marginRight: 5, fontSize: 14}}>Приоритет</p>
											<input type='number' value={type.priority} style={{maxWidth: 50}} onChange={(e) => {
												const newTypes = [...typesDeals];
												newTypes[i].priority = +e.target.value;
												setTypesDeals(newTypes);
											}}/>
										</div>

										<div style={{display: 'flex', alignItems: 'center', margin: 5}}>
											<p style={{marginRight: 5, fontSize: 14}}>Считать конверсии?</p>
											<input type='checkbox' checked={type.to_count_conversions} onChange={(e) => {
												const newTypes = [...typesDeals];
												newTypes[i].to_count_conversions = e.target.checked;
												setTypesDeals(newTypes);
											}}/>
										</div>

										<Button style={{marginTop: 5}} onClick={() => {
											const newTypes = [...typesDeals];
											setTypesDeals(newTypes.filter((_, k) => k !== i));
										}} appearance={'link'}>Удалить</Button>
									</div>

									<div>

										<div 
											style={{width: '100%', position: 'relative', maxWidth: 500, margin: 5, background: 'white', border: '1px solid #e2e1f4', borderRadius: 8, display: 'flex', padding: 10, height: 'fit-content', flexDirection: 'row', flexWrap: 'wrap'}}
											id='tagContainer'
										>
											{type.tags.map((tag, j) => (
												<div style={{display: 'flex', margin: 2, height: 26}}>

													<div style={{background: tag.color, padding: 4, borderRadius: '4px 0 0 4px'}}>
														<p style={{fontSize: 13, color: tinycolor.mostReadable(tag.color, ['#fff', '#000']).toHex()}}>{tag.name}</p>
													</div>

													<div 
														className={styles.tagDelete}
														onClick={() => {
															const newTypes = [...typesDeals];
															newTypes[i].tags = newTypes[i].tags.filter((_, k) => k !== j);
															setTypesDeals(newTypes);
														}}
													>
														<CrossIco className={styles.tagDeleteIco} />
													</div>

												</div>
											))}
									
											<input type="text" style={{border: 0}}
												onFocus={() => setActiveSearchName(type.type_name)}
												onChange={(e) => {
													searchTags(e.target.value);
												}}
											/>
										</div>

										{activeSearchName === type.type_name && searchedTags.length > 0 && 
										<div id='tagContainer' style={{position: 'absolute', border: '1px solid #e2e1f4', background: 'white', borderRadius: 8, padding: 10, zIndex: 1000, maxHeight: 200, overflow: 'auto', width: '100%', maxWidth: 500, margin: 5, marginTop: 0}}>
											{searchedTags.map((tagS) => {
												return (
													<div 
														style={{display: 'flex', margin: 2, height: 26}}
														onClick={() => {
															const newTypes = [...typesDeals];
															newTypes[i].tags = [...newTypes[i].tags, tagS];
															setTypesDeals(newTypes);
															setActiveSearchName('');
														}
														}
													>

														<div style={{background: tagS.color, padding: 4, borderRadius: 4, cursor: 'pointer', border: '1px solid #e2e1f4'}}>
															<p style={{fontSize: 13, color: tinycolor.mostReadable(tagS.color, ['ffffff', '#000']).toHex()}}>{tagS.name}</p>
														</div>

													</div>

												);
											})}
										</div>
										}

									</div>


								</div>
								<div style={{width: '100%', height: 1, background: 'grey', marginTop: 20, opacity: 0.2}}/>

							</>
						))}



						<Button style={{marginTop: 30, maxWidth: 320, margin: 10}} onClick={addType} appearance={'link'}>Добавить тип</Button>
						<Button style={{marginTop: 10, maxWidth: 320, margin: 10}} onClick={updateTypes} appearance={'auth'}>Обновить типы</Button>

					</div>
					
				</div>
				}

				{ activeCategory === 'leagues' &&
				<div>
					<div>
						{leagues.map((league, i) => (
							<>
								<div style={{display: 'flex', marginTop: 10, marginBottom: 10, flexWrap: 'wrap'}}>

									<div style={{margin: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
										<Input 
											textLabel='Название лиги' type='text' id='text' placeholder='Введите название лиги'
											value={league.league_name}
											onChange={(e) => {
												const newLeagues = [...leagues];
												newLeagues[i].league_name = e.target.value;
												setLeagues(newLeagues);
											}}
											style={{maxWidth: 300, margin: 0}}
										/>
										<Button style={{marginTop: 5}} onClick={() => {
											const newLeagues = [...leagues];
											setLeagues(newLeagues.filter((_, k) => k !== i));
										}} appearance={'link'}>Удалить</Button>
									</div>

									<div>

										<div 
											style={{width: '100%', position: 'relative', maxWidth: 500, margin: 5, background: 'white', border: '1px solid #e2e1f4', borderRadius: 8, display: 'flex', padding: 10, height: 'fit-content', flexDirection: 'row', flexWrap: 'wrap'}}
											id='tagContainer'
										>
											{league.norms.map((norm, j) => (
												<div style={{display: 'flex', margin: 2, height: 26}}>

													<div style={{background: '#ABB8C3', padding: 4, borderRadius: '4px 0 0 4px'}}>
														<p style={{fontSize: 13, color: 'black'}}>{norm.type_name}</p>
													</div>

													<input 
														style={{border: '1px solid #e2e1f4', maxWidth: 50, padding: 2}}
														type={'number'}
														value={norm.lead_week}
														onChange={(e) => {
															const newLeagues = [...leagues];
															newLeagues[i].norms[j].lead_week = +e.target.value;
															setLeagues(newLeagues);
														}}
													/>

													<div 
														className={styles.tagDelete}
														onClick={() => {
															const newLeagues = [...leagues];
															newLeagues[i].norms = newLeagues[i].norms.filter((_, k) => k !== j);
															setLeagues(newLeagues);
														}}
													>
														<CrossIco className={styles.tagDeleteIco} />
													</div>

												</div>
											))}
								
											<input type="text" style={{border: 0}}
												onFocus={() => setActiveSearchLeague(league.league_name)}
												onChange={(e) => {
													searchTypes(e.target.value);
												}}
											/>
										</div>

										{activeSearchLeague === league.league_name && searchedTypes.length > 0 && 
									<div id='tagContainer' style={{position: 'absolute', border: '1px solid #e2e1f4', background: 'white', borderRadius: 8, padding: 10, zIndex: 1000, maxHeight: 200, overflow: 'auto', width: '100%', maxWidth: 500, margin: 5, marginTop: 0}}>
										{searchedTypes.map((normSear) => {
											return (
												<div 
													style={{display: 'flex', margin: 2, height: 26}}
													onClick={() => {
														const newLeagues = [...leagues];
														newLeagues[i].norms = [...newLeagues[i].norms, { type_name: normSear, lead_week: 0 }];
														setLeagues(newLeagues);
														setActiveSearchLeague('');
													}
													}
												>

													<div style={{background: '#ABB8C3', padding: 4, borderRadius: 4, cursor: 'pointer', border: '1px solid #e2e1f4'}}>
														<p style={{fontSize: 13, color: 'black'}}>{normSear}</p>
													</div>

												</div>

											);
										})}
									</div>
										}

									</div>


								</div>
								<div style={{width: '100%', height: 1, background: 'grey', marginTop: 20, opacity: 0.2}}/>

							</>
						))}



						<Button style={{marginTop: 30, maxWidth: 320, margin: 10}} onClick={addLeague} appearance={'link'}>Добавить лигу</Button>
						<Button style={{marginTop: 10, maxWidth: 320, margin: 10}} onClick={updateLeagues} appearance={'auth'}>Обновить лиги</Button>

					</div>
				
				</div>
				}

				{ activeCategory === 'managers' &&
				<div>
					<div>
						{managersList.map((manager, i) => (
							<>
								<div style={{display: 'flex', marginTop: 10, marginBottom: 10, flexWrap: 'wrap'}}>

									<div style={{margin: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>

										<Select 
											selectedValue={manager.manager_id}
											onChange={(e) => {
												const newManagersList = [...managersList];
												// eslint-disable-next-line @typescript-eslint/ban-ts-comment
												//@ts-ignore
												newManagersList[i].manager_id = e.target.value;
												// eslint-disable-next-line @typescript-eslint/ban-ts-comment
												//@ts-ignore
												newManagersList[i].manager_name = managersAxl.find((managerAxl) => managerAxl.id === e.target.value)?.name || '';
												setManagersList(newManagersList);
											}}
											listCategories={managersAxl} 
											style={{minWidth: 250, width: 'max-content', marginBottom: 0}}
										/>

										<Button style={{marginTop: 5}} onClick={() => {
											const newManagersList = [...managersList];
											setManagersList(newManagersList.filter((_, k) => k !== i));
										}} appearance={'link'}>Удалить</Button>
									</div>

									<div>

										<Select 
											selectedValue={manager.league_name}
											onChange={(e) => {
												const newManagersList = [...managersList];
												// eslint-disable-next-line @typescript-eslint/ban-ts-comment
												//@ts-ignore
												newManagersList[i].league_name = e.target.value;
												setManagersList(newManagersList);
											}}
											listCategories={leaguesList} 
											style={{minWidth: 250, width: 'max-content', margin: 5}}
										/>

										<div style={{margin: 5}}>
											<p>Рабочие дни</p>

											<div style={{display: 'flex', alignItems: 'center'}}>

												<div style={{display: 'flex', alignItems: 'center', marginRight: 5}}>
													<p style={{marginRight: 5}}>Пн</p>
													<input type='checkbox' checked={manager.work_days.monday} onChange={(e) => {
														const newManagersList = [...managersList];
														newManagersList[i].work_days.monday = e.target.checked;
														setManagersList(newManagersList);
													}}/>
													<div style={{width: 2, height: 10, background: '#e2e1f4', marginLeft: 5}}/>
												</div>

												<div style={{display: 'flex', alignItems: 'center', marginRight: 5}}>
													<p style={{marginRight: 5}}>Вт</p>
													<input type='checkbox' checked={manager.work_days.tuesday} onChange={(e) => {
														const newManagersList = [...managersList];
														newManagersList[i].work_days.tuesday = e.target.checked;
														setManagersList(newManagersList);
													}}/>
													<div style={{width: 2, height: 10, background: '#e2e1f4', marginLeft: 5}}/>
												</div>

												<div style={{display: 'flex', alignItems: 'center', marginRight: 5}}>
													<p style={{marginRight: 5}}>Ср</p>
													<input type='checkbox' checked={manager.work_days.wednesday} onChange={(e) => {
														const newManagersList = [...managersList];
														newManagersList[i].work_days.wednesday = e.target.checked;
														setManagersList(newManagersList);
													}}/>
													<div style={{width: 2, height: 10, background: '#e2e1f4', marginLeft: 5}}/>
												</div>

												<div style={{display: 'flex', alignItems: 'center', marginRight: 5}}>
													<p style={{marginRight: 5}}>Чт</p>
													<input type='checkbox' checked={manager.work_days.thursday} onChange={(e) => {
														const newManagersList = [...managersList];
														newManagersList[i].work_days.thursday = e.target.checked;
														setManagersList(newManagersList);
													}}/>
													<div style={{width: 2, height: 10, background: '#e2e1f4', marginLeft: 5}}/>
												</div>

												<div style={{display: 'flex', alignItems: 'center', marginRight: 5}}>
													<p style={{marginRight: 5}}>Пт</p>
													<input type='checkbox' checked={manager.work_days.friday} onChange={(e) => {
														const newManagersList = [...managersList];
														newManagersList[i].work_days.friday = e.target.checked;
														setManagersList(newManagersList);
													}}/>
													<div style={{width: 2, height: 10, background: '#e2e1f4', marginLeft: 5}}/>
												</div>

												<div style={{display: 'flex', alignItems: 'center', marginRight: 5}}>
													<p style={{marginRight: 5}}>Сб</p>
													<input type='checkbox' checked={manager.work_days.saturday} onChange={(e) => {
														const newManagersList = [...managersList];
														newManagersList[i].work_days.saturday = e.target.checked;
														setManagersList(newManagersList);
													}}/>
													<div style={{width: 2, height: 10, background: '#e2e1f4', marginLeft: 5}}/>
												</div>

												<div style={{display: 'flex', alignItems: 'center', marginRight: 5}}>
													<p style={{marginRight: 5}}>Вс</p>
													<input type='checkbox' checked={manager.work_days.sunday} onChange={(e) => {
														const newManagersList = [...managersList];
														newManagersList[i].work_days.sunday = e.target.checked;
														setManagersList(newManagersList);
													}}/>
												</div>

													

											</div>

										</div>

									</div>

									<div>

									</div>


								</div>
								<div style={{width: '100%', height: 1, background: 'grey', marginTop: 20, opacity: 0.2}}/>

							</>
						))}



						<Button style={{marginTop: 30, maxWidth: 320, margin: 10}} onClick={addManager} appearance={'link'}>Добавить менеджера</Button>
						<Button style={{marginTop: 10, maxWidth: 320, margin: 10}} onClick={updateManagers} appearance={'auth'}>Обновить список</Button>

					</div>
			
				</div>
				}


				

			</div>

		</>
	);
});

export default Distribution;