/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from 'react';
import styles from './WheelofFortune.module.css';
import { observer } from 'mobx-react';
import { WheelofFortune } from '../../models/wheelOfFortune';
import { useApi } from '../../hooks/useApi';
import { useStores } from '../../hooks/useStore';
import { Button } from '../../components/UI/Button/Button';
import { Link, useNavigate } from 'react-router-dom';
import WheelofFortunePopUP from '../../components/popUp/WheelofFortune/WheelofFortune';
require('moment/locale/ru');


const WheelofFortunePage: React.FC = observer(() => {

	const store = useStores();
	const api = useApi();
	
	const [wheels, setWheels] = useState<WheelofFortune[]>([]);

	const navigate = useNavigate();

	useEffect(() => {
		(async () => {
			await loadWheels();
		})();
	}, []);

	const loadWheels = async () => {
		try {
			store.notificationsStore.showPreloader();
			const { body } = await api.wheelofFortune.getWheels(0, 50);

			if (body) {
				const sortedByDateCreated = body.sort((a, b) => {
					return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
				})
				setWheels(sortedByDateCreated);
			}
		} catch (error) {
			store.notificationsStore.setError('Ошибка загрузки данных');
		} finally {
			store.notificationsStore.hidePreloader();
		}
	}

	const [selectedWheel, setSelectedWheel] = useState<WheelofFortune | null>(null);
	const [showForm, setShowForm] = useState<boolean>(false);

	const closeForm = () => {
		setSelectedWheel(null);
		setShowForm(false);
	}

	const openForm = (item: WheelofFortune) => {
		setSelectedWheel(item);
		setShowForm(true);
	}

	const updateWheel = async () => {
		setSelectedWheel(null)
		await loadWheels();
	}

	

	return (
		<>
			{showForm && <WheelofFortunePopUP onClose={closeForm} update={updateWheel} wheel={selectedWheel}/>}
			<div className={styles.container}>
				<div className={styles.pageName}>
					<div className={styles.top}>
						<div className={styles.title}>
							<h2>Колеса фортуны</h2>
							<Button style={{width: 150, marginLeft: 10}} appearance='auth' onClick={() => {setShowForm(true)}}>Создать +</Button>
						</div>
					</div>

					<div>
						{wheels.length > 0 && wheels.map((wheel) => {
							return (
								<div 
									onClick={() => navigate(`/wheels/${wheel.id}`)}
									key={wheel.id}
									className={styles.wheelContainer}
								>
									<div className={styles.wheelName}>
										{wheel.name}
									</div>
									<div className={styles.wheelActions}>
										<Button style={{width: 200}} appearance='back' onClick={(e) => {e.stopPropagation(); openForm(wheel)}}>Редактировать</Button>
										{/* <Button style={{width: 100, marginLeft: 10}} appearance='link' onClick={() => {}}>Удалить</Button> */}
									</div>
								</div>
							)
						})}
					</div>

				</div>

				

			</div>
		</>
	);
});

export default WheelofFortunePage;