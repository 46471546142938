/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from 'react';
import styles from './WheelDetailsPage.module.css';
import { observer } from 'mobx-react';
import { WheelofFortune, WheelofFortunePrize } from '../../models/wheelOfFortune';
import { useApi } from '../../hooks/useApi';
import { useStores } from '../../hooks/useStore';
import { Button } from '../../components/UI/Button/Button';
import { useParams } from 'react-router-dom';
import WheelofFortunePopUP from '../../components/popUp/WheelofFortune/WheelofFortune';
import PrizePopUP from '../../components/popUp/Prize/Prize';
require('moment/locale/ru');


const WheelDetailsPage: React.FC = observer(() => {

	const { id } = useParams();

	const store = useStores();
	const api = useApi();
	
	const [wheel, setWheel] = useState<WheelofFortune | null>();
	const [prizes, setPrizes] = useState<WheelofFortunePrize[]>([]);

	const [totalProbability, setTotalProbability] = useState<number>(0);

	useEffect(() => {
		setTotalProbability(prizes.reduce((acc, prize) => acc + prize.probability, 0));
	}, [prizes]);

	useEffect(() => {
		(async () => {
			await loadWheel();
			await loadPrizes();
		})();
	}, []);

	const loadWheel = async () => {
		try {
			store.notificationsStore.showPreloader();
			if (id) {
				const { body } = await api.wheelofFortune.getWheelsById(id);

				if (body) {
					setWheel(body);
				}
			}
		} catch (error) {
			store.notificationsStore.setError('Ошибка загрузки данных');
		} finally {
			store.notificationsStore.hidePreloader();
		}
	}

	const loadPrizes = async () => {
		try {
			store.notificationsStore.showPreloader();
			if (id) {
				const { body } = await api.wheelofFortune.getPrizesForWheel(id, 0, 50);

				if (body) {
					setPrizes(body);
				}
			}
		} catch (error) {
			store.notificationsStore.setError('Ошибка загрузки данных');
		} finally {
			store.notificationsStore.hidePreloader();
		}
	}

	const deletePrize = async (idPrize: string) => {
		try {
			store.notificationsStore.showPreloader();
			if (id) {
				await api.wheelofFortune.deletePrize(idPrize);
				await loadPrizes();
			}
		} catch (error) {
			store.notificationsStore.setError('Ошибка загрузки данных');
		} finally {
			store.notificationsStore.hidePreloader();
		}
	}

	const [selectedPrize, setSelectedPrize] = useState<WheelofFortunePrize | null>(null);
	const [showForm, setShowForm] = useState<boolean>(false);

	const closeForm = () => {
		setSelectedPrize(null);
		setShowForm(false);
	}

	const openForm = (item: WheelofFortunePrize) => {
		setSelectedPrize(item);
		setShowForm(true);
	}

	const updatePrize = async () => {
		setSelectedPrize(null)
		await loadPrizes();
	}
	

	return (
		<>
			{showForm && id && <PrizePopUP onClose={closeForm} update={updatePrize} wheelId={id} prize={selectedPrize}/>}
			<div className={styles.container}>
				<div className={styles.pageName}>
					<div className={styles.top}>
						<div className={styles.title}>
							<div>
								<h2>{wheel?.name}</h2>
								<a style={{fontSize: 14, marginTop: 5}}>ID: {wheel?.id}</a>
								<p style={{fontSize: 14, marginTop: 5, color: totalProbability !== 100 ? 'red' : 'black'}}>Совокупный процент: {totalProbability}%</p>
							</div>
							<Button style={{width: 150, marginLeft: 15}} appearance='auth' onClick={() => {
								setShowForm(true);
							}}>Добавить приз</Button>
						</div>
					</div>
				</div>


				<div className={styles.prizes}>
					{prizes.length > 0 && prizes.map((prize) => {
						return (
							<div className={styles.prizeContainer} key={prize.id}>
								<div className={styles.prize}>
									<div className={styles.prizeName}>
										<img src={prize.image_url} alt='prize' style={{width: 50, height: 50, borderRadius: 500}}/>
										<h3>{prize.name}</h3>
									</div>
									<div className={styles.prizeDescription}>
										<p>Остаток: {prize.count}</p>
										<p>Вероятность: {prize.probability}</p>
									</div>
									<div className={styles.prizeActions}>
										<Button style={{width: 150, marginRight: 10}} appearance='link' onClick={() => {
											openForm(prize);
										}}>Изменить</Button>
									</div>
								</div>
							</div>
						)
					}
					)}
				</div>

				

			</div>
		</>
	);
});

export default WheelDetailsPage;