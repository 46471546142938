/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import styles from './Prize.module.css';
import { ReactComponent as Close } from '../ico/close.svg';
import { observer } from 'mobx-react';
import { useStores } from '../../../hooks/useStore';
import { useApi } from '../../../hooks/useApi';
import { Input } from '../../UI/input/input';
import {useEffect, useState } from 'react';
import { WheelofFortunePrize } from '../../../models/wheelOfFortune';
import CheckboxInput from '../../UI/finance/checkbox/Checkbox';
import { Button } from '../../UI/Button/Button';

interface PopupProps {
	wheelId: string;
  prize: WheelofFortunePrize | null;
  onClose: () => void;
	update: () => void;
}

const PrizePopUP: React.FC<PopupProps> = observer(({ wheelId, prize, onClose, update }) => {

	const store = useStores();
	const api = useApi();


	const [name, setName] = useState<string>('');
	const [imageUrl, setImageUrl] = useState<string>('');
	const [scenarioId, setScenarioId] = useState<string>('');
	const [count, setCount] = useState<number>(0);
	const [probability, setProbability] = useState<number>(0);


	const [disable, setDisable] = useState<boolean>(true);

	useEffect(() => {
		if (name !== '' && imageUrl !== '' && scenarioId !== '' && count !== 0 && probability !== 0) {
			setDisable(false);
		} else {
			setDisable(true);
		}
	},[name, imageUrl, scenarioId, count, probability]);

	useEffect(() => {
		if (prize) {
			setName(prize.name);
			setImageUrl(prize.image_url);
			setScenarioId(prize.scenario_id);
			setCount(prize.count);
			setProbability(prize.probability);
		}
	}, [prize]);


	const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		// Проверяем, что клик произошел именно на overlay, а не на его содержимое
		if (event.target === event.currentTarget) {
			onClose();
		}
	};

	const handleButtonClick = async () => {
		try {
			store.notificationsStore.showPreloader();

			const data = {
				name,
				wheel_id: wheelId,
				image_url: imageUrl,
				scenario_id: scenarioId,
				count,
				probability
			}

			if (prize) {
				await api.wheelofFortune.updatePrize(prize.id, data);
			} else {
				await api.wheelofFortune.createPrize(data);
			}

			update();
			onClose();
			store.notificationsStore.setNotifications('Приз ' + (prize ? 'изменено' : 'создано'));

		} catch (e) {
			store.notificationsStore.setError('Ошибка обработки приза');
		} finally {
			store.notificationsStore.hidePreloader();
		}
	}
	
	return (
		<>
			<div className={styles.container} onClick={handleOverlayClick}>
				<div className={styles.form}>
					<Close className={styles.close} onClick={onClose}/>
					<h3>{prize ? 'Изменение' : 'Создание'} приза</h3>
					<div className={styles.line} style={{marginBottom: 20}}/>

					<Input style={{maxWidth: '90%'}} value={name} placeholder='Название колеса' textLabel='Название колеса' onChange={(event) => setName(event.target.value)}/>

					<Input style={{maxWidth: '90%'}} value={imageUrl} placeholder='URL изображения' textLabel='URL изображения' onChange={(event) => setImageUrl(event.target.value)}/>

					<Input style={{maxWidth: '90%'}} value={scenarioId} placeholder='ID сценария' textLabel='ID сценария' onChange={(event) => setScenarioId(event.target.value)}/>

					<Input style={{maxWidth: '90%'}} value={count} type='number' placeholder='Количество' textLabel='Количество' onChange={(event) => setCount(Number(event.target.value))}/>

					<Input style={{maxWidth: '90%'}} value={probability} type='number' placeholder='Вероятность' textLabel='Вероятность' onChange={(event) => setProbability(Number(event.target.value))}/>


					<div className={styles.line} style={{marginBottom: 10}}/>

					<Button disabled={disable} onClick={handleButtonClick} style={{margin: 5, maxWidth: 320, marginBottom: 25}} appearance='auth'>Создать</Button>

				</div>
			</div>
		</>
	);
});

export default PrizePopUP;
