/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import styles from './WheelofFortune.module.css';
import { ReactComponent as Close } from '../ico/close.svg';
import { observer } from 'mobx-react';
import { useStores } from '../../../hooks/useStore';
import { useApi } from '../../../hooks/useApi';
import { Input } from '../../UI/input/input';
import {useEffect, useState } from 'react';
import { WheelofFortune } from '../../../models/wheelOfFortune';
import CheckboxInput from '../../UI/finance/checkbox/Checkbox';
import { Button } from '../../UI/Button/Button';

interface PopupProps {
  wheel: WheelofFortune | null;
  onClose: () => void;
	update: () => void;
}

const WheelofFortunePopUP: React.FC<PopupProps> = observer(({ wheel, onClose, update }) => {

	const store = useStores();
	const api = useApi();


	const [name, setName] = useState<string>('');
	const [itsFree, setItsFree] = useState<boolean>(false);

	const [idFreeCount, setIdFreeCount] = useState<string>('');
	const [keyFreeCount, setKeyFreeCount] = useState<string>('');

	const [idPaidCount, setIdPaidCount] = useState<string>('');
	const [keyPaidCount, setKeyPaidCount] = useState<string>('');

	const [scenarioIdForUpdate, setScenarioIdForUpdate] = useState<string>('');

	const [disable, setDisable] = useState<boolean>(true);

	useEffect(() => {
		if (name !== '' && idFreeCount !== '' && keyFreeCount !== '' && idPaidCount !== '' && keyPaidCount !== '' && scenarioIdForUpdate !== '') {
			setDisable(false);
		} else {
			setDisable(true);
		}
	},[name, idFreeCount, keyFreeCount, idPaidCount, keyPaidCount, scenarioIdForUpdate]);

	useEffect(() => {
		if (wheel) {
			setName(wheel.name);
			setItsFree(wheel.its_free);
			setIdFreeCount(wheel.id_free_count);
			setKeyFreeCount(wheel.key_free_count);
			setIdPaidCount(wheel.id_paid_count);
			setKeyPaidCount(wheel.key_paid_count);
			setScenarioIdForUpdate(wheel.scenario_id_for_update);
		}
	}, [wheel]);


	const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		// Проверяем, что клик произошел именно на overlay, а не на его содержимое
		if (event.target === event.currentTarget) {
			onClose();
		}
	};

	const handleButtonClick = async () => {
		try {
			store.notificationsStore.showPreloader();

			const data = {
				name,
				its_free: itsFree,
				id_free_count: idFreeCount,
				key_free_count: keyFreeCount,
				id_paid_count: idPaidCount,
				key_paid_count: keyPaidCount,
				scenario_id_for_update: scenarioIdForUpdate
			}

			if (wheel) {
				await api.wheelofFortune.updateWheel(wheel.id, data);
			} else {
				await api.wheelofFortune.createWheel(data);
			}

			update();
			onClose();
			store.notificationsStore.setNotifications('Колесо ' + (wheel ? 'изменено' : 'создано'));

		} catch (e) {
			store.notificationsStore.setError('Ошибка обработки колеса');
		} finally {
			store.notificationsStore.hidePreloader();
		}
	}
	
	return (
		<>
			<div className={styles.container} onClick={handleOverlayClick}>
				<div className={styles.form}>
					<Close className={styles.close} onClick={onClose}/>
					<h3>{wheel ? 'Изменение' : 'Создание'} колеса</h3>
					<div className={styles.line} style={{marginBottom: 20}}/>

					<Input style={{maxWidth: '90%'}} value={name} placeholder='Название колеса' textLabel='Название колеса' onChange={(event) => setName(event.target.value)}/>

					<CheckboxInput style={{width: '90%', marginBottom: 20}} defaultValue={itsFree} labelText='Это бесплатное колесо?' result={setItsFree}/>

					<Input style={{maxWidth: '90%'}} value={idFreeCount} placeholder='ID бесплатного поля прокруток' textLabel='ID бесплатного поля прокруток' onChange={(event) => setIdFreeCount(event.target.value)}/>

					<Input style={{maxWidth: '90%'}} value={keyFreeCount} placeholder='Ключ бесплатного поля прокруток' textLabel='Ключ бесплатного поля прокруток' onChange={(event) => setKeyFreeCount(event.target.value)}/>

					<Input style={{maxWidth: '90%'}} value={idPaidCount} placeholder='ID платного поля прокруток' textLabel='ID платного поля прокруток' onChange={(event) => setIdPaidCount(event.target.value)}/>

					<Input style={{maxWidth: '90%'}} value={keyPaidCount} placeholder='Ключ платного поля прокруток' textLabel='Ключ платного поля прокруток' onChange={(event) => setKeyPaidCount(event.target.value)}/>

					<Input style={{maxWidth: '90%'}} value={scenarioIdForUpdate} placeholder='ID сценария для обновления' textLabel='ID сценария для обновления' onChange={(event) => setScenarioIdForUpdate(event.target.value)}/>


					<div className={styles.line} style={{marginBottom: 10}}/>

					<Button disabled={disable} onClick={handleButtonClick} style={{margin: 5, maxWidth: 320, marginBottom: 25}} appearance='auth'>Создать</Button>

				</div>
			</div>
		</>
	);
});

export default WheelofFortunePopUP;
