/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import styles from './Checkbox.module.css';

interface CheckboxInputProps {
	mustTrue?: boolean;
	labelText: string;
	result: (chech: boolean) => void;
	disabled?: boolean;
	marginTop?: number;
	defaultValue?: boolean;
	style?: React.CSSProperties;
}

const CheckboxInput: React.FC<CheckboxInputProps> = observer(({result, mustTrue, labelText, marginTop = 0, defaultValue, style }) => {

	const [checked, setChecked] = useState<boolean>(false);

	useEffect(() => {
		result(checked);
	}, [checked]);

	useEffect(() => {
		if (defaultValue) {
			setChecked(defaultValue);
		}
	}, [defaultValue]);

	return (
		<div className={styles.lineFormContainer} style={{marginTop, color: mustTrue ? '#999' : 'black', ...style}}>
			<div className={styles.lineFormLabel}></div>

			<div className={styles.containerMain}>
				<label className={styles.container}>
					<input
						disabled={mustTrue}
						type="checkbox"
						checked={!mustTrue ? checked : true}
						onChange={() => setChecked(!checked)}
						className={styles.checkbox}
					/>
					<span className={styles.checkmark}></span>
				</label>
			</div>
			
			{labelText}

		</div>

	)
	
});

export default CheckboxInput;