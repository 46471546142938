/* eslint-disable no-unused-vars */
import { WheelofFortune, WheelofFortunePrize } from '../../models/wheelOfFortune';
import ApiClient from '../ApiClient';
import { NiceResponse } from '../dto/NiceResponse';
import { CreateWheelPrizeRequest } from './dto/CreateWheelPrizeRequest.dto';
import { CreateWheelRequest } from './dto/CreateWheelRequest.dto';

export class WheelofFortuneApi {
	constructor(private api: ApiClient) { }

	path = '/wheel-of-fortune';

	async createWheel(req: CreateWheelRequest) {
		const data = await this.api.client.post<NiceResponse<WheelofFortune>>(this.path + '/add', { ...req });
		return data.data;
	}

	async getWheels(skip: number, take: number) {
		const data = await this.api.client.get<NiceResponse<WheelofFortune[]>>(this.path + '/get', { params: { skip, take } });
		return data.data;
	}

	async getWheelsById(id: string) {
		const data = await this.api.client.get<NiceResponse<WheelofFortune>>(this.path + '/get/' + id);
		return data.data;
	}

	async updateWheel(id: string, req: CreateWheelRequest) {
		const data = await this.api.client.put<NiceResponse<WheelofFortune>>(this.path + '/update/' + id, { ...req });
		return data.data;
	}

	async deleteWheel(id: string) {
		const data = await this.api.client.delete<NiceResponse<null>>(this.path + '/delete/' + id);
		return data.data;
	}

	async createPrize(req: CreateWheelPrizeRequest) {
		const data = await this.api.client.post<NiceResponse<WheelofFortunePrize>>(this.path + '/prize/add', { ...req });
		return data.data;
	}

	async getPrizesForWheel(wheelId: string, skip: number, take: number) {
		const data = await this.api.client.get<NiceResponse<WheelofFortunePrize[]>>(this.path + '/prize/get-wheel/' + wheelId, { params: { skip, take } });
		return data.data;
	}

	async getPrizeById(id: string) {
		const data = await this.api.client.get<NiceResponse<WheelofFortunePrize>>(this.path + '/prize/get/' + id);
		return data.data;
	}

	async updatePrize(id: string, req: CreateWheelPrizeRequest) {
		const data = await this.api.client.put<NiceResponse<WheelofFortunePrize>>(this.path + '/prize/update/' + id, { ...req });
		return data.data;
	}

	async deletePrize(id: string) {
		const data = await this.api.client.delete<NiceResponse<null>>(this.path + '/prize/delete/' + id);
		return data.data;
	}

}